// utils/auth.js

export function isAuthenticated() {
    const token = localStorage.getItem('authToken');
    if (!token) {
        return false;
    }

    try {
        // Decode the token to check its expiration time
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Date.now() / 1000;

        return decodedToken.exp > currentTime;
    } catch (e) {
        // If decoding fails, log the user out
        console.error("Token decoding failed", e);
        return false;
    }
}

export function logout() {
    // Remove token and other user-related information from localStorage
    localStorage.removeItem('authToken');
    window.location.href = '/login'; // Redirect to login page
}
