import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/UserSlice';

const store = configureStore({
    reducer: {
        user: userReducer, // User slice
    },
});

export default store;
