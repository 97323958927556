import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const Logo = styled(Typography)(({ theme, fontSize, display }) => ({
  fontFamily: 'Pacifico',
  fontWeight: 400,
  fontStyle: 'normal',
  fontOpticalSizing: 'auto',
  fontSize: fontSize || '1rem',
  [theme.breakpoints.up('xs')]: {
    display: display?.xs || 'block',
  },
  [theme.breakpoints.up('sm')]: {
    display: display?.sm || 'block',
  },
  [theme.breakpoints.up('md')]: {
    display: display?.md || 'block',
  },
  [theme.breakpoints.up('lg')]: {
    display: display?.lg || 'block',
  },
}));

function LuckyDuckLogo({ fontSize, display, padding }) {
    return <Logo fontSize={fontSize} display={display} padding={padding}>LuckyDuck</Logo>;
}

export default LuckyDuckLogo;
