import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Blue color
        },
        secondary: {
            main: '#ff4081', // Pink color
        },
    },
    logoColor: '#ffffff',
    typography: {
      fontFamily: ['"Libre Franklin"', 'Roboto', 'Open Sans'].join(',')
    },
});

export default theme;
