import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Lotteries from './pages/Lotteries';
import Login from './pages/Login';
import TopBar from './components/TopBar'; // Import the TopBar component
import LotteryDetails from './components/LotteryDetails';
import BallotRegister from './components/BallotRegister';
import AdminDashboard from './components/AdminDashboard';
import { Container } from '@mui/material';
import theme from './Theme';

function Layout() {
    const location = useLocation();
    const onLandingPage = location.pathname === '/'; // Landing page condition

    return (
        <>
            {/* Conditionally render the TopBar only on routes other than the landing page */}
            <TopBar />

            {/* Conditionally wrap in a Container when not on the landing page */}
            {onLandingPage ? (
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            ) : (
                <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
                    <Routes>
                        <Route path="/lottery/:id" element={<LotteryDetails />} />
                        <Route path="/register/:lotteryId/ballot/:code" element={<BallotRegister />} />
                        <Route path="/admin" element={<AdminDashboard />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/lotteries" element={<Lotteries />} />
                    </Routes>
                </Container>
            )}
        </>
    );
}

function App() {
    return (
        <Router>
            <Layout />
        </Router>
    );
}

export default App;
