// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Empty Cards Style */
.empty-card {
  background-color: #f5f5f5;
}

/* Page break for print */
.page-break {
  display: none;
}

@media print {
  /* Force page breaks before and after the ballot section */
  .page-break {
      display: block;
      page-break-after: always;
  }

  /* Ensure QR codes are always visible */
  .ballot-card svg {
      display: block !important;
  }

}
`, "",{"version":3,"sources":["webpack://./src/LotteryDetails.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,yBAAyB;AAC3B;;AAEA,yBAAyB;AACzB;EACE,aAAa;AACf;;AAEA;EACE,0DAA0D;EAC1D;MACI,cAAc;MACd,wBAAwB;EAC5B;;EAEA,uCAAuC;EACvC;MACI,yBAAyB;EAC7B;;AAEF","sourcesContent":["/* Empty Cards Style */\n.empty-card {\n  background-color: #f5f5f5;\n}\n\n/* Page break for print */\n.page-break {\n  display: none;\n}\n\n@media print {\n  /* Force page breaks before and after the ballot section */\n  .page-break {\n      display: block;\n      page-break-after: always;\n  }\n\n  /* Ensure QR codes are always visible */\n  .ballot-card svg {\n      display: block !important;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
