import React from 'react';
import LotteryList from '../components/LotteryList'; // Import LotteryList

function Lotteries() {
    return (
        <LotteryList />
    );
}

export default Lotteries;
