import React, { useState, useEffect } from 'react';
import axiosInstance from '../AxiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container, Card, CardContent, Typography, TextField, Button, Box,
    Grid, Snackbar, CardMedia, CircularProgress, Alert
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import CheckBoxTwoTone from '@mui/icons-material/CheckBoxTwoTone';

function BallotRegister() {
    const { lotteryId, code } = useParams();
    const [form, setForm] = useState({ name: '', phoneNumber: '', email: '' });
    const [lottery, setLottery] = useState(null);
    const [ballot, setBallot] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [notFound, setNotFound] = useState(false); // 404 state
    const [alreadyRegistered, setAlreadyRegistered] = useState(false); // State for already registered ballot

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the lottery and ballot details by the code
        axiosInstance.get(`/api/lottery/${lotteryId}/ballot/${code}`)
            .then(response => {
                setBallot(response.data);
                setIsRegistered(response.data.isRegistered);
                setAlreadyRegistered(response.data.isRegistered); // Set if already registered
                setIsLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setNotFound(true); // Set 404 if ballot doesn't exist
                } else {
                    setError(true);
                }
                setIsLoading(false);
            });
    }, [lotteryId, code]);

    const handleSubmit = (event) => {
        event.preventDefault();
        axiosInstance.post(`/api/lottery/${lotteryId}/ballot/${code}/register`, form)
            .then(response => {
                setIsRegistered(true);
                setOpen(true);
            })
            .catch(error => {
                console.error(error);
                setError(true);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    if (isLoading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (notFound) {
        return (
            <Container>
                <Alert severity="error">This ballot does not exist. Please check the link or contact the lottery administrator.</Alert>
                <Box textAlign="center" mt={4}>
                    <Button variant="contained" onClick={() => navigate('/')}>
                        Go Back to Home
                    </Button>
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Alert severity="error">There was an issue fetching the lottery information.</Alert>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" style={{ marginTop: '2rem' }}>
            <Card elevation={3}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        {lottery?.name || 'Go-Dream holiday in Europe for 2!'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        {lottery?.description || 'Register to get the chance to win a Go-Dream holiday in Europe for 2! A dream for two people to enjoy together.'}
                    </Typography>

                    {/* If the ballot is already registered, show the checkmark and info */}
                    {alreadyRegistered ? (
                        <Box textAlign="center" mt={4}>
                            <Typography variant="h5" gutterBottom>
                                This ballot has already been registered.
                            </Typography>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Card elevation={2} style={{ position: 'relative', width: '250px', height: '250px' }}>
                                    <CardContent>
                                        <CardMedia title="QR code">
                                            <QRCodeSVG
                                                value={`${window.location.origin}/register/${lotteryId}/ballot/${code}`}
                                                size="100%"
                                            />
                                        </CardMedia>
                                    </CardContent>
                                    <Box
                                        position="absolute"
                                        right="0"
                                        bottom="0"
                                        width="100%"
                                        height="100%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light overlay
                                            zIndex: 1,
                                        }}
                                    >
                                        <CheckBoxTwoTone
                                            sx={{ fontSize: '4.5rem', color: 'green', opacity: 0.5 }}
                                        />
                                    </Box>
                                </Card>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            {/* Display registration form if ballot is not registered */}
                            {!isRegistered ? (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Name"
                                                fullWidth
                                                value={form.name}
                                                onChange={handleChange}
                                                name="name"
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Phone Number"
                                                fullWidth
                                                value={form.phoneNumber}
                                                onChange={handleChange}
                                                name="phoneNumber"
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Email"
                                                fullWidth
                                                value={form.email}
                                                onChange={handleChange}
                                                name="email"
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                style={{ marginTop: '1rem' }}
                                            >
                                                Register Ballot
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            ) : (
                                <Box textAlign="center" mt={4}>
                                    <Typography variant="h5" gutterBottom>
                                        Ballot Registered Successfully!
                                    </Typography>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Card elevation={2} style={{ position: 'relative', width: '250px', height: '250px' }}>
                                            <CardContent>
                                                <CardMedia title="QR code">
                                                    <QRCodeSVG
                                                        value={`${window.location.origin}/register/${lotteryId}/ballot/${code}`}
                                                        size="100%"
                                                    />
                                                </CardMedia>
                                            </CardContent>
                                            <Box
                                                position="absolute"
                                                right="0"
                                                bottom="0"
                                                width="100%"
                                                height="100%"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light overlay
                                                    zIndex: 1,
                                                }}
                                            >
                                                <CheckBoxTwoTone
                                                    sx={{ fontSize: '4.5rem', color: 'green', opacity: 0.5 }}
                                                />
                                            </Box>
                                        </Card>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </CardContent>
            </Card>

            {/* Snackbar for successful registration */}
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message="Ballot registered successfully!"
            />
        </Container>
    );
}

export default BallotRegister;
