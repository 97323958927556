import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import CasinoIcon from '@mui/icons-material/Casino';
import RedeemIcon from '@mui/icons-material/Redeem';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import LuckyDuckLogo from '../components/LuckyDuckLogo';
import Typewriter from 'typewriter-effect';
import { isAuthenticated } from '../utils/Auth'; // Function to check login status
import theme from '../Theme';

// Custom background styling for the hero section
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#ffffff',
    background: 'linear-gradient(135deg, #ece9d655 25%, transparent 25%) -40px 0/ 80px 80px, linear-gradient(225deg, #ece9d6 25%, transparent 25%) -40px 0/ 80px 80px, linear-gradient(315deg, #ece9d655 25%, transparent 25%) 0px 0/ 80px 80px, linear-gradient(45deg, #ece9d6 25%, #ffffff 25%) 0px 0/ 80px 80px',
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
}));

function Home() {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in
        setLoggedIn(isAuthenticated());
    }, []);

    return (
        <Box>
            {/* Hero Section with custom background */}
            <HeroSection>
                <Container>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                sx={{
                                    height: { xs: 250, sm: 280, md: 340 },
                                    display: 'block',
                                    margin: 'auto',
                                }}
                                alt="Lucky Duck"
                                src="luckyDuck.png"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="center">
                            <LuckyDuckLogo
                                theme={theme}
                                fontSize={{
                                    xs: '4rem',
                                    sm: '5rem',
                                    md: '6rem',
                                    lg: '7rem',
                                }}
                            />
                            <Box mt={2}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: { xs: '1.5em', sm: '1.4em', md: '1.6em' },
                                    }}
                                >
                                    <Typewriter
                                        options={{
                                            strings: [
                                                'Gamify your purchase flow!',
                                                'Physically and digitally!',
                                                'Boost sales with incentives!',
                                                'Physical QR code ballots!',
                                                'e-commerce platform integration!',
                                                'Glittering prizes!',
                                                'Automatic, fair draws!',
                                            ],
                                            autoStart: true,
                                            deleteSpeed: 30,
                                            delay: 30,
                                            loop: true,
                                        }}
                                    />
                                </Typography>
                            </Box>
                            <Box mt={3}>
                                {loggedIn ? (
                                    <Button
                                        component={Link}
                                        to="/lotteries"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{ marginRight: 2 }}
                                    >
                                        My Lotteries
                                    </Button>
                                ) : null}

                                {!loggedIn ? (
                                    <Button
                                        component={Link}
                                        to="/login"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                    >
                                        Log in
                                    </Button>
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </HeroSection>

            {/* Features Section */}
            <Container sx={{ marginTop: 6 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Why use <LuckyDuckLogo display={{ lg: 'inline' }} fontSize="2.5rem" />?
                </Typography>
                <Typography variant="h6" align="center" color="textSecondary" paragraph>
                    Unlock the power of winning with exciting lotteries, fair draws, and a chance to make your dreams come true.
                </Typography>

                <Grid container spacing={4} sx={{ marginTop: 4 }}>
                    <Grid item xs={12} sm={4}>
                        <Box textAlign="center" p={3} boxShadow={3} bgcolor="white">
                            <CasinoIcon sx={{ fontSize: 50, color: '#1976d2' }} />
                            <Typography variant="h6" gutterBottom>
                                Fair Draws
                            </Typography>
                            <Typography>
                                Every spin, every draw – completely random and fair, giving everyone an equal chance to win.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box textAlign="center" p={3} boxShadow={3} bgcolor="white">
                            <RedeemIcon sx={{ fontSize: 50, color: '#ff4081' }} />
                            <Typography variant="h6" gutterBottom>
                                Exciting Prizes
                            </Typography>
                            <Typography>
                                From gadgets to grand prizes, <LuckyDuckLogo display={{ lg: 'inline' }} fontSize="1rem" /> offers a wide variety of rewards to make winning even sweeter.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box textAlign="center" p={3} boxShadow={3} bgcolor="white">
                            <ThumbUpIcon sx={{ fontSize: 50, color: '#1976d2' }} />
                            <Typography variant="h6" gutterBottom>
                                Easy to Use
                            </Typography>
                            <Typography>
                                Seamless and intuitive interface, designed to make your lottery experience fun and easy.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            {/* Call to Action Section */}
            <Box mt={6} textAlign="center" sx={{ backgroundColor: '#f5f5f5', padding: '3rem 0' }}>
                <Typography variant="h4" gutterBottom>
                    Ready to Take a Spin?
                </Typography>
                <Typography variant="h6" color="textSecondary" paragraph>
                    Join and increase your revenue!
                </Typography>
                {!loggedIn ? (
                    <Button
                        component={Link}
                        to="/login"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ marginRight: 2 }}
                    >
                        Sign Up Now
                    </Button>
                ) : null}

                <Button
                    component={Link}
                    to="/lotteries"
                    variant="outlined"
                    color="secondary"
                    size="large"
                >
                    {loggedIn ? 'My Lotteries' : 'Explore Lotteries'}
                </Button>
            </Box>
        </Box>
    );
}

export default Home;
