import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../AxiosInstance';
import { useParams, Link } from 'react-router-dom';
import {
    Card, CardContent, Typography, Button, Grid, CardMedia, Box,
    Snackbar, TextField, Radio, RadioGroup, FormControlLabel,
    FormControl, FormLabel, Divider,
    CardActions,
    CardActionArea,
    Alert
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import CheckBoxTwoTone from '@mui/icons-material/CheckBoxTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GavelIcon from '@mui/icons-material/Gavel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import confetti from 'canvas-confetti';
import '../LotteryDetails.css';
import { CheckCircleOutline } from '@mui/icons-material';

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

function LotteryDetails() {
    const { id } = useParams();
    const [lottery, setLottery] = useState(null);
    const [ballots, setBallots] = useState([]);
    const [winningBallots, setWinningBallots] = useState([]); // New state for winning ballots
    const [searchText, setSearchText] = useState('');
    const [includeRegistered, setIncludeRegistered] = useState('all');
    const [open, setOpen] = useState(false);
    const gruntAudioRef = useRef(null);
    const sambaAudioRef = useRef(null);

    useEffect(() => {
        // Fetch lottery and ballot details
        axiosInstance.get(`/api/lottery/${id}`)
            .then(response => {
                setLottery(response.data);
                setWinningBallots(response.data.winningBallots); // Set winning ballots from response
            })
            .catch(error => console.error(error));

        axiosInstance.get(`/api/lottery/${id}/ballot`)
            .then(response => setBallots(response.data))
            .catch(error => console.error(error));
    }, [id]);

    if (!lottery) return <Typography variant="h5">Loading...</Typography>;

    const filteredBallots = ballots.filter((ballot) => {
        const matchesSearch = ballot.code.toLowerCase().includes(searchText.toLowerCase());
        const matchesRegistration =
            includeRegistered === 'all' ||
            (includeRegistered === 'registered' && ballot.isRegistered) ||
            (includeRegistered === 'notRegistered' && !ballot.isRegistered);

        return matchesSearch && matchesRegistration;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleDrawWinners = () => {
        axiosInstance.post(`/api/lottery/${id}/draw`)
            .then(() => {
                axiosInstance.get(`/api/lottery/${id}`)
                    .then(response => {
                        const gruntAudio = gruntAudioRef.current;
                        const sambaAudio = sambaAudioRef.current;
                        confetti({
                            particleCount: 2000,
                            startVelocity: 300,
                            origin: {
                                x: 0.5, y: 1.0
                            },
                            shapes: ['circle', 'star', 'square'],
                            scalar: 1,
                            ticks: 2000,
                        });
                        gruntAudio.play().then(sambaAudio.play());
                        setWinningBallots(response.data.winningBallots);
                    })
                    .catch(error => console.error(error));
            })
            .catch((error) => {
                console.error(error);
                alert('Failed to draw winners.');
            });
    };

    const handleGenerateBallots = () => {
        axiosInstance.post(`/api/lottery/${id}/ballot/generate/25`)
            .then(() => {
                axiosInstance.get(`/api/lottery/${id}/ballot`)
                    .then(response => setBallots(response.data))
                    .catch(error => console.error(error));
            })
            .catch((error) => {
                console.error(error);
                alert('Failed to generate ballots.');
            });
    };

    const totalBallots = ballots.length;
    const registeredBallots = ballots.filter(ballot => ballot.isRegistered).length;
    const isLotteryExpired = new Date(lottery.endDate) <= new Date();

    // Sort ballots and check if they are winners
    const sortedBallots = filteredBallots.sort((a, b) => {
        const aIsWinner = winningBallots.some(winner => winner.id === a.id); // Check if ballot is a winner
        const bIsWinner = winningBallots.some(winner => winner.id === b.id);

        if (aIsWinner && !bIsWinner) return -1;
        if (!aIsWinner && bIsWinner) return 1;
        return 0;
    });

    return (
        <>
            <Grid container spacing={2} justifyContent="center" style={{ marginTop: '2rem' }}>
                {/* Main Lottery Card with Summary */}
                <Grid item xs={12} md={8}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                {lottery.name}
                            </Typography>

                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                {lottery.description || 'No description.'}
                            </Typography>

                            <Box display="flex" alignItems="center" mb={1}>
                                <EventIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                                <Typography variant="body1">
                                    Start Date: {formatDate(lottery.startDate)}
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center" mb={1}>
                                <AccessTimeIcon sx={{ marginRight: 1, color: 'green' }} />
                                <Typography variant="body1">
                                    End Date: {formatDate(lottery.endDate)}
                                </Typography>
                            </Box>

                            <Divider sx={{ marginY: 2 }} />

                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center">
                                        <PeopleIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                                        <Typography variant="h6">
                                            Total Ballots: {totalBallots}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center">
                                        <CheckCircleIcon sx={{ marginRight: 1, color: 'green' }} />
                                        <Typography variant="h6">
                                            Registered Ballots: {registeredBallots}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardActions>
                            <Box display="flex" justifyContent="center" mt={2} gap={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<GavelIcon />}
                                    onClick={handleDrawWinners}
                                    disabled={!lottery.isDrawAvailable || winningBallots.length !== 0}
                                >
                                    Draw Winners
                                </Button>

                                <audio ref={gruntAudioRef} src={process.env.PUBLIC_URL + "/grunt.mp3"} />
                                <audio ref={sambaAudioRef} src={process.env.PUBLIC_URL + "/samba.mp3"} />

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddCircleIcon />}
                                    onClick={handleGenerateBallots}
                                    disabled={isLotteryExpired}
                                >
                                    Generate 25 Ballots
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>

                {/* Filters Section */}
                <Grid item xs={12} md={8}>
                    <Card elevation={3} style={{ marginBottom: '1.5rem' }}>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Search Ballots"
                                        variant="outlined"
                                        fullWidth
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        InputProps={{
                                            startAdornment: <SearchIcon position="start" />,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            <FilterListIcon /> Include Ballots
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            value={includeRegistered}
                                            onChange={(e) => setIncludeRegistered(e.target.value)}
                                        >
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                            <FormControlLabel value="registered" control={<Radio />} label="Registered" />
                                            <FormControlLabel value="notRegistered" control={<Radio />} label="Not Registered" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {/* Page break after ballots */}
                    <div className="page-break" />

                    <Grid container spacing={2}>
                        {sortedBallots.map(ballot => {
                            const isWinner = winningBallots.some(winner => winner.id === ballot.id); // Check if ballot is a winner
                            return (
                                <Grid item xs={6} sm={4} md={4} lg={3} key={ballot.id}>
                                    <Box position="relative">
                                        <Button
                                            size="small"
                                            fullWidth
                                            href={`http://luckyduck.maneo.dk/register/${lottery.id}/ballot/${ballot.code}`}
                                        >
                                            <Card elevation={2}>
                                                <CardContent>
                                                    {isWinner ? (
                                                        <Box>
                                                            <Box display="flex" alignItems="center" mb={1} maxWidth="100%">
                                                                <PersonIcon />
                                                                <Typography variant="body1" ml={1} noWrap={false} style={{ flexGrow: 1, minWidth: 0, overflowWrap: 'break-word' }}>
                                                                    {ballot.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box display="flex" alignItems="center" mb={1} maxWidth="100%">
                                                                <EmailIcon />
                                                                <Typography variant="body1" ml={1} style={{ flexGrow: 1, minWidth: 0, overflowWrap: 'break-word' }}>
                                                                    {ballot.email}
                                                                </Typography>
                                                            </Box>
                                                            <Box display="flex" alignItems="center" maxWidth="100%">
                                                                <PhoneIcon />
                                                                <Typography variant="body1" ml={1} style={{ flexGrow: 1, minWidth: 0, overflowWrap: 'break-word' }}>
                                                                    {ballot.phoneNumber}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        ) : (
                                                            <CardMedia title="QR code">
                                                                <QRCodeSVG
                                                                    value={`http://luckyduck.maneo.dk/register/${lottery.id}/ballot/${ballot.code}`}
                                                                    size='100%'
                                                                />
                                                            </CardMedia>)}
                                                    <Typography style={{ fontSize: '8px' }}>{ballot.id}</Typography>
                                                </CardContent>
                                            </Card>
                                        </Button>

                                        {(ballot.isRegistered || isWinner) && (
                                            <>
                                                    <Box
                                                        position="absolute"
                                                        right="0"
                                                        bottom="0"
                                                        width="100%"
                                                        height="100%"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    sx={{
                                                        backgroundColor: 'rgba(128, 128, 128, 0.7)', // Gray overlay
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    {isWinner ? (
                                                        <EmojiEventsIcon
                                                            sx={{ fontSize: '4.5rem', color: 'gold', opacity: 0.8 }}
                                                        />
                                                    ) : (
                                                        <CheckBoxTwoTone
                                                            sx={{ fontSize: '4.5rem', color: 'green', opacity: 0.5 }}
                                                        />
                                                    )}
                                                </Box>
                                                <Box
                                                    position="absolute"
                                                    left="0"
                                                    top="0"
                                                    width="100%"
                                                    height="100%"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{
                                                        zIndex: 2,
                                                    }}
                                                >
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>


                    {/* Page break after ballots */}
                    <div className="page-break" />

                    <Grid container spacing={2}>
                        {sortedBallots.map(ballot => {
                            return (
                                <Grid item xs={6} sm={4} md={4} lg={3} key={ballot.id}>
                                    <Box position="relative">
                                        <Button
                                                size="small"
                                                component={Link}
                                                fullWidth
                                            >
                                            <Card elevation={2}>
                                                <CardContent>
                                                    <CardMedia title="image">
                                                        <img
                                                            style={{
                                                                maxWidth: '95%',
                                                                height: 'auto',
                                                                display: 'block',
                                                                margin: 'auto',
                                                            }}
                                                            alt="Logo"
                                                            src={`${process.env.PUBLIC_URL}/hatten.png`}
                                                        />
                                                    </CardMedia>
                                                    <CardActionArea>
                                                        <Typography variant='h4' style={{ marginTop: '15px', marginBotton: '15px', fontSize: '14px', textAlign: 'center' }}>Scan to win a Go-dream holiday in Europe for 2! 🛫</Typography>
                                                    </CardActionArea>
                                                </CardContent>
                                            </Card>
                                        </Button>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>

            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                message="Register link copied to clipboard."
            />
        </>
    );
}

export default LotteryDetails;
