import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { isAuthenticated } from '../utils/Auth'; // Import the authentication check

function AdminDashboard() {
    const navigate = useNavigate(); // Replace useHistory with useNavigate

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login'); // Redirect to login if not authenticated
        }
    }, [navigate]);

    return (
        <div>
            <h2>Admin Dashboard</h2>
            {/* Admin dashboard content */}
        </div>
    );
}

export default AdminDashboard;
