import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../slices/UserSlice';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Paper, Typography } from '@mui/material';
import axiosInstance from '../AxiosInstance';  // Make sure you're using the axios instance to send the login request

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);  // To handle login errors
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        axiosInstance.post('/api/auth/login', { username, password })
            .then(response => {
                const { token } = response.data;

                // Store token and username in localStorage
                localStorage.setItem('authToken', token);
                localStorage.setItem('username', username);

                // Dispatch login action to Redux
                dispatch(login({ username }));

                // Redirect to home page
                navigate('/');
            })
            .catch(error => {
                setError('Login failed. Please check your credentials.');
            });
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={8} md={4}>
                <Paper elevation={3} style={{ padding: '2rem' }}>
                    <Typography variant="h4" gutterBottom>
                        Login
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>}
                    <form onSubmit={handleLogin}>
                        <TextField
                            label="Username"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop: '1rem' }}
                        >
                            Login
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Login;
