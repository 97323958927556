import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../slices/UserSlice'; // Import logout action
import LuckyDuckLogo from './LuckyDuckLogo';

function TopBar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Access authentication state from Redux store
    const { isLoggedIn, username } = useSelector((state) => state.user);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleUserClick = () => {
        navigate('/user');
    };

    const handleLogout = () => {
        // Remove the token and username from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');

        // Dispatch the logout action to Redux
        dispatch(logout());

        // Redirect to the login page
        navigate('/');
    };
  
    // Navigation links
    const navLinks = [
        { text: 'Home', path: '/', onlyIfLoggedIn: false },
        { text: 'Lotteries', path: '/lotteries', onlyIfLoggedIn: true },
    ];

    return (
        <AppBar position="static">
            <Toolbar>
                {/* Mobile Menu Button */}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>

                {/* App Title */}
                <Typography sx={{ flexGrow: 1, textAlign: { xs: 'center', sm: 'unset'} }}>
                    <LuckyDuckLogo fontSize='1.4rem' />
                </Typography>

                {/* Navigation Links for Desktop */}
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {navLinks.map((link) => (
                      <Button
                          key={link.text}
                          color="inherit"
                          component={Link}
                          to={link.path}
                          sx={{ display: { xs: 'none', sm: 'block' } }}
                      >
                          {link.text}
                      </Button>
                  ))}
                  {/* Login Button or Username */}
                  {isLoggedIn ? (
                      <>
                          <Button color="inherit" onClick={handleLogout}>
                              Logout
                          </Button>
                      </>
                  ) : (
                      <Button color="inherit" component={Link} to="/login">
                          Login
                      </Button>
                  )}
                </div>
            </Toolbar>

            {/* Drawer for Mobile Navigation */}
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    style={{ width: 250 }}
                >
                    <List>
                        {navLinks.map((link) => (
                            <ListItem button key={link.text} component={Link} to={link.path}>
                                <ListItemText primary={link.text} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
        </AppBar>
    );
}

export default TopBar;
