import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../AxiosInstance';
import {
    Grid, Card, CardContent, Typography, Button, CircularProgress, Box, Divider, LinearProgress
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxTwoTone from '@mui/icons-material/CheckBoxTwoTone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

function LotteryList() {
    const [lotteries, setLotteries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');

    useEffect(() => {
        axiosInstance.get('/api/lottery')
            .then(response => {
                setLotteries(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const filteredLotteries = lotteries.filter(lottery => {
        if (filter === 'over') return !lottery.isDrawAvailable;
        if (filter === 'winners') return lottery.winningBallots.length > 0;
        return true;
    });

    if (loading) {
        return (
            <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            {/* Filter Controls */}
            <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
                <Button
                    variant={filter === 'all' ? 'contained' : 'outlined'}
                    startIcon={<FilterListIcon />}
                    onClick={() => setFilter('all')}
                >
                    All Lotteries
                </Button>
                <Button
                    variant={filter === 'over' ? 'contained' : 'outlined'}
                    startIcon={<AccessTimeIcon />}
                    onClick={() => setFilter('over')}
                    style={{ marginLeft: '10px' }}
                >
                    Ongoing Lotteries
                </Button>
                <Button
                    variant={filter === 'winners' ? 'contained' : 'outlined'}
                    startIcon={<EmojiEventsIcon />}
                    onClick={() => setFilter('winners')}
                    style={{ marginLeft: '10px' }}
                >
                    Lotteries with Winners
                </Button>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '2rem' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" gutterBottom>
                        My Lotteries
                    </Typography>
                </Grid>
                {filteredLotteries.map(lottery => (
                    <Grid item xs={12} sm={6} md={4} key={lottery.id} style={{ display: 'flex' }}>
                        <Card elevation={3} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <CardContent style={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom noWrap>
                                    {lottery.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    gutterBottom
                                >
                                    {lottery.description || 'No description available.'}
                                </Typography>

                                {/* Dates */}
                                <Box display="flex" alignItems="center" mb={1}>
                                    <EventIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                                    <Typography variant="body1">
                                        Start: {formatDate(lottery.startDate)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={1}>
                                    <AccessTimeIcon sx={{ marginRight: 1, color: 'green' }} />
                                    <Typography variant="body1">
                                        End: {formatDate(lottery.endDate)}
                                    </Typography>
                                </Box>

                                {/* Progress Bar */}
                                <Box sx={{ mt: 2 }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={lottery.progressPercentage}
                                        sx={{ height: 10, borderRadius: 5 }}
                                    />
                                </Box>

                                {/* Divider */}
                                <Divider sx={{ marginY: 2 }} />

                                {/* Ballots and Status */}
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={4}>
                                        <Box display="flex" alignItems="center">
                                            <LocalActivityIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                                            <Typography variant="h6">
                                                {lottery.numberOfBallots}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box display="flex" alignItems="center">
                                            <CheckBoxTwoTone sx={{ marginRight: 1, color: 'green' }} />
                                            <Typography variant="h6">
                                                {lottery.numberOfRegisteredBallots}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box display="flex" alignItems="center">
                                            <EmojiEventsIcon sx={{ marginRight: 1, color: 'gold' }} />
                                            <Typography variant="h6">
                                                {lottery.numberOfWinners}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            {/* View Details Button */}
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={`/lottery/${lottery.id}`}
                                fullWidth
                                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                            >
                                View Details
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default LotteryList;
