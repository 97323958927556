import { createSlice } from '@reduxjs/toolkit';

// Check localStorage for auth token and username
const token = localStorage.getItem('authToken');
const username = localStorage.getItem('username');

const initialState = {
    isLoggedIn: !!token, // Set to true if token exists
    username: username || null, // Set username if it exists in localStorage
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            state.username = action.payload.username;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.username = null;
        },
    },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
